export const variants = {
  slideIn: {
    initial: {
      opacity: 0,
      x: -20,
    },
    animate: {
      opacity: 1,
      x: 0,
    },
  },
  slideInFull: {
    initial: {
      opacity: 0,
      x: "-100%",
    },
    animate: {
      opacity: 1,
      x: 0,
    },
  },
  slideInReverseFull: {
    initial: {
      opacity: 0,
      x: "100%",
    },
    animate: {
      opacity: 1,
      x: 0,
    },
  },
  slideInReverse: {
    initial: {
      opacity: 0,
      x: 30,
    },
    animate: {
      opacity: 1,
      x: 0,
    },
  },
  slideUpFull: {
    initial: {
      y: "100%",
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
    },
  },
  slideUp: {
    initial: {
      y: 20,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
    },
  },
  slideDown: {
    initial: {
      y: -15,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
    },
  },
  slideDownFull: {
    initial: {
      y: "-100%",
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
    },
  },
  scale: {
    initial: {
      opacity: 0,
      scale: 0.95,
    },
    animate: {
      opacity: 1,
      scale: 1,
    },
  },
  fade: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
  },
  container: {
    initial: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
    animate: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
  },
}

export const translatedStrings = {
  CLOSE: {
    it: "CHIUDI",
    en: "CLOSE",
  },
  CONTACT_INTRO_1: {
    it: "Avete domande per noi?",
    en: "Got a Question?",
  },
  CONTACT_INTRO_2: {
    it: "Mandateci un messaggio.",
    en: "Drop us a line.",
  },
  CONTACT_INTRO_3: {
    it:
      "Siamo qui per aiutarvi, e saremo felici di rispondere a tutte le vostre domande e richieste. Contattateci subito, vi risponderemo al più presto.",
    en:
      "We’re here to help and we will be happy to answer any question and inquiry you might have.",
  },
  CONTACTS: {
    it: "",
    en: "",
  },
  COOKIE_BANNER_1: {
    it:
      "Il sito NeverEndingVibes.com utilizza i cookies (tecnici e di profilazione) per garantirti una migliore esperienza di navigazione e per offrirti, quando sei online, messaggi pubblicitari personalizzati in linea con le tue preferenze. Per saperne di più leggi la nostra",
    en:
      "The NeverEndingVibes.com site uses cookies (technical and profiling) to ensure you an optimal browsing experience and to offer you when you are online, personalized advertising messages in line with your preferences. To find out more, read our",
  },
  COOKIE_BANNER_2: {
    it: "Privacy Policy",
    en: "Privacy policy.",
  },
  COOKIE_BUTTON_1: {
    it: "Accetta",
    en: "Accept",
  },
  COOKIE_BUTTON_2: {
    it: "Rifiuta",
    en: "Decline",
  },
  EMAIL: {
    it: "Email",
    en: "Email",
  },
  ERROR_FORM: {
    it: "Si è verificato un problema. Ricarica la pagina e riprova.",
    en: "Oops, there's been a problem. Please refresh the page and try again.",
  },
  LAST_NAME: {
    it: "Cognome",
    en: "Last name",
  },
  MENU: {
    it: "",
    en: "",
  },
  MESSAGE: {
    it: "Messaggio",
    en: "Message",
  },
  NAME: {
    it: "Nome",
    en: "First Name",
  },
  SEND: {
    it: "Invia",
    en: "Send",
  },
  SUCCESS_FORM: {
    it: "Grazie per aver contattato NEV! Ti risponderemo al più presto.",
    en: "Thank you for contacting NEV! We'll be in touch shortly.",
  },
  CONTACT_BUTTON: {
    it: "Contattaci",
    en: "Get in touch",
  },
}
