import { AnimatePresence, motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import { translatedStrings, variants } from "@src/constants"

import Cookies from "js-cookie"
import { addTracking } from "@src/helpers"
import styled from "styled-components"
import useLanguage from "@components/hooks/useLanguage"

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false)
  const { lang } = useLanguage()

  function setCookieHandler() {
    Cookies.set("cookie_policy", "true", { expires: 31 })
    addTracking()
    return setShowBanner(false)
  }

  function rejectCookieHandler() {
    Cookies.set("cookie_policy", "false")

    return setShowBanner(false)
  }

  useEffect(() => {
    if (!Cookies.get("cookie_policy")) {
      setShowBanner(true)
    } else if (
      Cookies.get("cookie_policy") &&
      Cookies.get("cookie_policy") === "true"
    ) {
      addTracking()
    }
  }, [])

  return (
    <AnimatePresence>
      {showBanner && (
        <StyledBanner
          key="cookie-banner"
          initial="initial"
          animate="animate"
          exit="initial"
          variants={{
            initial: {
              ...variants.slideUpFull.initial,
              x: "-50%",
            },
            animate: {
              ...variants.slideUpFull.animate,
              x: "-50%",
            },
          }}
          transition={{
            duration: 0.45,
            ease: "easeInOut",
          }}
          className="cookie-banner"
        >
          <p>
            {translatedStrings["COOKIE_BANNER_1"][lang]}{" "}
            <a href="/privacy-policy">
              {translatedStrings["COOKIE_BANNER_2"][lang]}
            </a>
          </p>
          <button className="btn-accept" onClick={() => setCookieHandler()}>
            {translatedStrings["COOKIE_BUTTON_1"][lang]}
          </button>
          <button className="btn-decline" onClick={() => rejectCookieHandler()}>
            {translatedStrings["COOKIE_BUTTON_2"][lang]}
          </button>
        </StyledBanner>
      )}
    </AnimatePresence>
  )
}

const StyledBanner = styled(motion.div)`
  width: 100%;
  max-width: 1024px;
  padding: 20px;
  background-color: #135193;
  color: #ffffff;
  text-align: center;
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 10;

  p {
    margin-top: 0;
    font-size: 13px;
  }

  a {
    color: #ffffff;
    text-decoration: underline;
    font-size: 13px;
  }

  button {
    display: inline-block;
    font-family: inherit;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    font-size: 13px;
  }

  .btn-accept {
    padding: 7px 10px;
    background-color: #ffffff;
    color: #135193;
    border: 0;
    margin-right: 7px;
  }

  .btn-decline {
    padding: 5px 10px;
    background-color: #135193;
    color: #ffffff;
    border: 2px solid #ffffff;
  }
`

CookieBanner.displayName = "CookieBanner"

export default CookieBanner
