import React, { createContext, useContext, useEffect, useState } from "react"

// Create Context Object
const ResizeHandlerContext = createContext()

// Create a provider for components to consume and subscribe to changes
export const ResizeHandlerProvider = ({ children }) => {
  const [mobileDevice, setMobileDevice] = useState(false)

  useEffect(() => {
    function detectHandler() {
      if (window.innerWidth > 991 && mobileDevice) {
        setMobileDevice(false)
      } else if (window.innerWidth <= 991 && !mobileDevice) {
        setMobileDevice(true)
      }
    }

    detectHandler()

    window.addEventListener("resize", detectHandler, {
      passive: true,
    })

    return () =>
      window.removeEventListener("resize", detectHandler, {
        passive: true,
      })
  }, [mobileDevice])

  return (
    <ResizeHandlerContext.Provider value={{ mobileDevice }}>
      {children}
    </ResizeHandlerContext.Provider>
  )
}

ResizeHandlerProvider.displayName = "ResizeHandlerProvider"

// export for methods
export default () => useContext(ResizeHandlerContext)
