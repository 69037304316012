import React, { useEffect, useState } from "react"

import loadable from "@loadable/component"
import styled from "styled-components"
import { useLocation } from "@reach/router"

const MobileNav = loadable(() => import("@components/MobileNav"))
const Logo = loadable(() => import("@components/Logo"))
const LangSwitch = loadable(() => import("@components/common/LangSwitch"))

const Header = ({ language, translations, hideNavs }) => {
  const [showNav, setShowNav] = useState(false)
  const { pathname } = useLocation()

  function mobileNavHandler() {
    return setShowNav(!showNav)
  }

  useEffect(() => {
    if (showNav) {
      setShowNav(false)
    }
  }, [pathname])

  return (
    <StyledHeader>
      <Logo />
      <StyledOptions>
        <button
          type="button"
          onClick={mobileNavHandler}
          className="menu-trigger"
        >
          <figure className="icon">
            <img src="/img/icons/bars.svg" alt="" />
          </figure>
        </button>
        {!hideNavs && (
          <LangSwitch lang={language} translations={translations} />
        )}
      </StyledOptions>
      <MobileNav
        language={language}
        translations={translations}
        showNav={showNav}
        closeNav={setShowNav}
      />
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  position: relative;
  z-index: 10;

  @media (max-width: 991px) {
    ::after {
      content: "";
      position: fixed;
      width: 100%;
      height: 75px;
      background: #ffffff;
      top: 0;
      left: 0;
    }
  }

  header .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    z-index: -1;
    background: white;
    display: none;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    transition: 0.5s ease-in-out opacity;
  }
  header .menu::before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: transparent url(/img/background/lines.svg) center repeat-x;
    opacity: 0.3;
  }
  @media (max-width: 991px) {
    header .menu::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 75px;
      background: #ffffff;
      -webkit-transform: translateY(-100%);
      -moz-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
      -o-transform: translateY(-100%);
      transform: translateY(-100%);
      opacity: 0;
      -webkit-transition: 0.5s ease-in-out all;
      -moz-transition: 0.5s ease-in-out all;
      -ms-transition: 0.5s ease-in-out all;
      -o-transition: 0.5s ease-in-out all;
      transition: 0.5s ease-in-out all;
    }
  }
  header .menu.show {
    opacity: 1;
  }
  header .menu.show::after {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  header .menu .logo {
    z-index: 1;
  }
  @media (max-width: 991px) {
    header .menu .logo {
      position: fixed;
      top: 20px;
      left: 30px;
      width: 85px;
    }
    header .menu .logo img {
      max-width: 100%;
    }
  }
`

const StyledOptions = styled.section`
  position: absolute;
  top: 50px;
  right: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 991px) {
    position: fixed;
    z-index: 10;
    right: 30px;
    top: 20px;
  }

  .menu-trigger {
    display: none;
    width: 30px;
    height: 30px;
    margin: 0 0 10px 0;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    position: relative;
  }

  @media (max-width: 991px) {
    .menu-trigger {
      display: block;
    }
  }

  .menu-trigger .icon {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .menu-trigger .icon img {
    width: 100%;
  }
`

Header.displayName = "Header"

export default Header
