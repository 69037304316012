import { AnimatePresence, motion } from "framer-motion"
import React, { useEffect, useState } from "react"

import CookieBanner from "@components/CookieBanner"
import Footer from "@components/common/Footer"
import GlobalStyle from "@styles/general"
import Header from "@components/common/Header"
import { Helmet } from "react-helmet"
import { LanguageProvider } from "@components/hooks/useLanguage"
import PropTypes from "prop-types"
import { ResizeHandlerProvider } from "@components/hooks/useResizeHandler"
import styled from "styled-components"

const Main = ({ children, pageContext, location }) => {
  const { language, translations, hideNavs, noIndex } = pageContext
  const [lang, setLang] = useState(language)

  useEffect(() => {
    if (language && lang === language) return
    setLang(language)
  }, [language, lang])

  return (
    <LanguageProvider value={{ lang }}>
      <ResizeHandlerProvider>
        <Helmet>
          {noIndex && <meta name="robots" content="noindex" />}
          <style type="text/css">{`
          @font-face {
            font-family: 'nexa';
            src: url('/fonts/nexa_bold-webfont.woff') format('woff');
            font-weight: 700;
            font-style: normal;
          }
          @font-face {
            font-family: 'nexa';
            src: url('/fonts/NexaRegular.woff') format('woff');
            font-weight: 400;
            font-style: normal;
          }
          @font-face {
            font-family: 'nexa';
            src: url('/fonts/NexaHeavy.woff') format('woff');
            font-weight: 900;
            font-style: normal;
          }
        `}</style>
        </Helmet>
        <GlobalStyle />
        <Header
          lang={language}
          translations={translations}
          hideNavs={hideNavs}
        />
        <AnimatePresence exitBeforeEnter>
          <StyledMain
            key={location.pathname}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.4,
              ease: "easeOut",
            }}
          >
            {children}
          </StyledMain>
        </AnimatePresence>
        <CookieBanner />
        {!hideNavs && <Footer />}
      </ResizeHandlerProvider>
    </LanguageProvider>
  )
}

const StyledMain = styled(motion.main)`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  @media (min-width: 992px) {
    position: absolute;
  }

  @media (max-width: 991px) {
    position: relative;
  }
`

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Main
