import { AnimatePresence, motion } from "framer-motion"
import styled, { css } from "styled-components"

import { Link } from "gatsby"
import React from "react"
import { variants } from "@src/constants"

const NavList = ({ items, position, itemClassName, action, ismobile }) => {
  return (
    <AnimatePresence>
      {items.map((item, i) => {
        return (
          <StyledListItem
            key={`${i}-item`}
            position={position}
            className={itemClassName}
            ismobile={ismobile}
            initial="initial"
            animate="animate"
            variants={ismobile ? {} : variants.slideIn}
            transition={{
              duration: 0.35,
              delay: 0.1 * i,
              ease: "easeInOut",
            }}
          >
            <StyledLink
              to={item.url}
              activeClassName="active"
              className={itemClassName}
              ismobile={ismobile}
              onClick={() => {
                if (action) {
                  action()
                }
              }}
            >
              <StyledText
                ismobile={ismobile}
                dangerouslySetInnerHTML={{ __html: item.label }}
              />
            </StyledLink>
          </StyledListItem>
        )
      })}
    </AnimatePresence>
  )
}

const StyledListItem = styled(motion.li)`
  position: relative;
  margin-bottom: 5px;

  ${({ ismobile }) =>
    ismobile &&
    css`
      margin-bottom: 30px;

      @media (min-width: 992px) {
        margin-bottom: 0;
      }
    `}

  ${({ position }) =>
    position === "left" &&
    css`
      @media (max-width: 991px) {
        margin-bottom: 20px;
      }
    `}
`

const StyledLink = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  color: black;

  ::before,
  ::after {
    content: "";
    position: absolute;
    top: 50%;
    border-radius: 100%;
    transition: 0.3s ease-in-out all;
  }

  ::before {
    left: -5px;
    transform: translateY(-50%) translateX(-2px);
    width: 10px;
    height: 10px;
  }

  ::after {
    left: -7px;
    transform: translateY(-50%) translateX(-3px);
    border: 2px solid #135193;
    width: 12px;
    height: 12px;
  }

  &.active::before,
  :hover::before {
    background: #135193;
  }

  ${({ ismobile }) =>
    ismobile &&
    css`
      text-decoration: none;
      color: #2e2e2e;
      transition: 0.3s ease-in-out color;

      ::before,
      ::after {
        display: none;
      }

      :hover,
      :active,
      :focus {
        color: #135193;
      }

      &.active {
        color: #135193;
      }
    `}
`

const StyledText = styled.span`
  font-size: 12px;
  line-height: 12px;
  padding: 0 0 0 20px;
  color: inherit;
  transition: 0.3s ease-in-out all;

  ${({ ismobile }) =>
    ismobile &&
    css`
      font-size: 30px;
      line-height: 30px;
    `}
`

NavList.displayName = "NavList"

export default NavList
