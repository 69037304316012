export const addTracking = () => {
  const pixel1 = `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window,document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${process.env.GATSBY_PIXEL_ID}'); 
    fbq('track', 'PageView');`
  const pixel2 = document.createElement("img")
  pixel2.setAttribute("height", "1")
  pixel2.setAttribute("width", "1")
  pixel2.setAttribute(
    "src",
    `https://www.facebook.com/tr?id=${process.env.GATSBY_PIXEL_ID}&ev=PageView&noscript=1`
  )
  const gtm1 = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${process.env.GATSBY_GTM_ID}');`
  const gtm2 = document.createElement("iframe")
  gtm2.setAttribute(
    "src",
    `https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GTM_ID}`
  )
  gtm2.setAttribute("height", "0")
  gtm2.setAttribute("width", "0")
  gtm2.setAttribute("style", "display:none;visibility:hidden")

  // Pixel
  const pixelScript = document.createElement("script")
  const pixelNoscript = document.createElement("noscript")

  pixelScript.innerHTML = pixel1
  pixelNoscript.appendChild(pixel2)

  // GTM
  const gtmScript = document.createElement("script")
  const gtmNoscript = document.createElement("noscript")

  gtmScript.innerHTML = gtm1
  gtmNoscript.appendChild(gtm2)

  // Append
  document.head.appendChild(pixelScript)
  document.head.appendChild(pixelNoscript)
  document.head.appendChild(gtmScript)
  document.body.insertAdjacentElement("afterbegin", gtmNoscript)
}

export const sanitizeUrl = url => {
  const raw = url.split(process.env.GATSBY_API_URL)

  return raw.join("/")
}
