import React, { createContext, useContext, useEffect, useState } from "react"

// Create Context Object
const LanguageContext = createContext()

// Create a provider for components to consume and subscribe to changes
export const LanguageProvider = ({ children, value }) => {
  const [lang, setLang] = useState(value.lang || "en")

  useEffect(() => {
    if (lang === value.lang) return

    setLang(value.lang)
  }, [value, lang])

  return (
    <LanguageContext.Provider value={{ lang, setLang }}>
      {children}
    </LanguageContext.Provider>
  )
}

LanguageProvider.displayName = "LanguageProvider"

// export for methods
export default () => useContext(LanguageContext)
