import PrimaryNav from "@components/common/PrimaryNav"
import React from "react"
import SecondaryNav from "@components/common/SecondaryNav"
import styled from "styled-components"

const Footer = () => (
  <StyledFooter>
    <PrimaryNav />
    <SecondaryNav />
  </StyledFooter>
)

const StyledFooter = styled.footer`
  z-index: 5;
  ${"" /* display: none; */}

  ${"" /* footer.show {
    display: block;
  } */}

  @media (max-width: 991px) {
    position: relative;
    padding: 0 35px;
    margin-bottom: 30px;
  }
  
  ${
    "" /* footer .submenu .items {
    margin: 0;
    padding: 0;
    list-style: none;
  } */
  }
  ${
    "" /* footer .submenu .items .item {
    position: relative;
    margin-bottom: 5px;
  } */
  }
  ${
    "" /* footer .submenu .items .item .link {
    text-decoration: none;
    cursor: pointer;
  } */
  }
  ${
    "" /* footer .submenu .items .item .link.active::before,
  footer .submenu .items .item .link:hover::before {
    background: #135193;
  } */
  }
  ${
    "" /* footer .submenu .items .item .link .text {
    font-size: 12px;
    line-height: 12px;
    padding: 0 0 0 20px;
    color: black;
    transition: 0.3s ease-in-out all;
  } */
  }
  ${
    "" /* footer .submenu .items .item .link::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -7px;
    transform: translateY(-50%) translateX(-3px);
    border: 2px solid #135193;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    transition: 0.3s ease-in-out all;
  }
  footer .submenu .items .item .link::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -5px;
    transform: translateY(-50%) translateX(-2px);
    width: 10px;
    height: 10px;
    border-radius: 100%;
    transition: 0.3s ease-in-out all;
  } */
  }
`

Footer.displayName = "Footer"

export default Footer
