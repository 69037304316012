import { graphql, useStaticQuery } from "gatsby"

import NavList from "@components/common/NavList"
import React from "react"
import styled from "styled-components"
import useLanguage from "@components/hooks/useLanguage"

const query = graphql`
  {
    allWpMenu {
      nodes {
        menuItems {
          nodes {
            url
            label
          }
        }
        slug
      }
    }
  }
`

const PrimaryNav = ({ ismobile }) => {
  const { lang } = useLanguage()
  const {
    allWpMenu: { nodes: menus },
  } = useStaticQuery(query)
  const menu = menus.filter(menu => menu.slug.includes(`primary-${lang}`))[0]

  if (!menu || menu.length <= 0) return null

  return (
    <StyledList>
      <NavList
        items={menu.menuItems.nodes}
        position="left"
        ismobile={ismobile}
      />
    </StyledList>
  )
}

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  @media (min-width: 992px) {
    position: absolute;
    bottom: 50px;
    left: 50px;
  }
`

PrimaryNav.displayName = "PrimaryNav"

export default PrimaryNav
