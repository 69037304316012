import animations from "./animations"
import { createGlobalStyle } from "styled-components"
import styledNormalize from "styled-normalize"

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  ${animations}
  * {
    box-sizing: border-box;
  }

  html,
  body,
  figure {
    padding: 0;
    margin: 0;
    font-family: 'nexa';
  }

  @media (min-width: 992px) {
    body {
      height: 100vh;
    }
  }

  @media (max-width: 991px) {
    body {
      padding: 100px 0 0 0;
      max-width: 100vw;
    }
  }

  body::before {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s ease-in-out max-height, 0.7s ease-in-out max-width;
    z-index: 0;
    background: transparent url(/img/background/lines.svg) center repeat-x;
    opacity: 0.1;
  }

  @media (min-width: 992px) {
    body main {
      display: flex;
    }
  }

  @media (max-width: 991px) {
    body main {
      display: block;
      ${"" /* padding: 0 30px 0 30px; */}
    }
  }
`

export default GlobalStyle
